.pc-chart {
  &__container {
    position: relative;
    width: 100%;
    height: rem(370px);

    .pc-chart__item {
      width: 100%;
    }
  }

  &__data {
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translate3d(-50%, 0, 0);

    &--pie {
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    h3 {
      font-size: rem(28px);
    }

    span {
      color: $grey-600;
      font-size: rem(16px);
    }
  }

  &__stats {
    background-color: transparent;
    border: none;

    > * {
      padding: rem($gutter-size 0);

      &:not(:first-child) {
        padding: rem(($gutter-size / 2) 0);
      }
    }

    &-type {
      display: inline-flex;
      border-radius: 100%;
      width: rem(24px);
      height: rem(24px);
    }
  }

  &__item {
    &--pie {
      svg {
        padding: rem(0 $gutter-size);
      }
    }

    svg {
      overflow: visible;

      text {
        fill: $black;
      }
    }
  }

  .recharts-cartesian-axis-line {
    &[orientation='bottom'] {
      stroke: $primary-color;
    }

    &[orientation='left'] {
      display: none;
    }
  }

  .recharts-cartesian-grid-vertical,
  .recharts-tooltip-cursor {
    display: none;
  }

  .ebs-calendar__wrapper {
    max-width: rem(238px);
  }
}
