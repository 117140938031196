.ebs-table .rc-table-cell {
  .ebs-action__wrapper {
    margin-right: 0;
    margin-left: auto;
  }
}
.ebs-table.ebs-table--breakable-cell .rc-table-tbody tr.rc-table-row td.rc-table-cell {
  white-space: normal;
  word-break: break-word;
}

.ebs-table {
  // Clickable cell
  .ebs-table__cell--clickable {
    cursor: pointer;

    @include hover-state {
      color: $primary-color;

      // If cell contains avatar
      .ebs-avatar__inline-alt {
        color: $primary-color;
      }
    }
  }

  &.rc-table thead > tr:first-child > th,
  &.rc-table tbody > tr > td {
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  .rc-table-tbody tr.rc-table-row td.rc-table-cell {
    overflow-wrap: break-word;
  }

  &.rc-table-fixed-header {
    border: 1px solid $border-color;

    .rc-table-body,
    .rc-table-header {
      border: 0;
    }
    .rc-table-tbody tr.rc-table-row:last-child td {
      border-bottom: 0;
    }
  }
}
