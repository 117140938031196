.pc-comments {
  overflow: hidden auto;
  padding-bottom: 2rem;
  max-height: 50vh;
  height: 100%;
}

// FIXME: Fix media
@media (min-width: 928px) {
  .pc-comments__container {
    max-height: 50vh;
    height: 100%;
  }
}
