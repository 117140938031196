.pc-history-modal .ebs-modal {
  max-width: rem($grid-xl-size);
}

.pc-history-table {
  .rc-table-content,
  .ebs-card {
    border: none;
  }

  .ebs-tabs {
    width: 100%;
    background: $white;
    padding: 0 $gutter-size;

    &__item.active {
      border-bottom: 1px solid $primary-color;
    }
  }

  .ebs-label--fill .ebs-icon {
    color: $white;
  }

  .ebs-label.icon {
    width: rem($gutter-size / 2 * 3);
    height: rem($gutter-size / 2 * 3);
  }

  .ebs-table.rc-table tbody td.rc-table-cell,
  .ebs-table.rc-table thead th.rc-table-cell {
    padding: rem($gutter-size);
  }

  .pc-history__cols {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .pc-history__item {
    display: flex;
    justify-content: space-between;
    padding: rem(0 0 $gutter-size);
    width: 100%;

    .ebs-label:not(:first-child) {
      margin-left: rem($gutter-size / 2);
    }

    .pc-history__cols .pc-history__item {
      padding: rem(0 0 $gutter-size / 2);

      > .ebs-label:first-child {
        margin: rem(0 $gutter-size / 2);
      }
    }

    &,
    &:first-child {
      text-align: left;
    }

    &:last-child {
      padding: 0;
    }
  }
}
