.pc-confirmation-modal {
  .ebs-modal__content {
    .ebs-icon {
      font-size: rem(70px);

      &--alert {
        path:nth-child(2) {
          fill: $white;
        }

        path:last-child {
          fill: $black;
        }
      }
    }
  }
}
