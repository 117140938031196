.beneficiary {
  // Members
  &__member {
    margin-top: rem(-20px);
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: rem(4px);

    &:empty {
      border: none;
    }

    &__item {
      padding: rem(10px);

      &:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }

    &__count {
      width: rem(24px);
      height: rem(24px);
      border-radius: rem(12px);
      font-size: rem(14px);
      line-height: rem(23px);
      text-align: center;
      font-weight: 600;
      border: 1px solid $border-color;
    }
  }
}
