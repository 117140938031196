@import '~@ebs-platform/connect/dist/styles/index.scss';
@import './palette', './variables', './typography';

@import '~ebs-design/dist/styles/index';
@import '~ebs-design/dist/styles/default';
@import '~ebs-design/dist/styles/scrollbar';

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p,
span {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

a {
  color: $primary-color;
  transition: 0.2s;

  &:hover {
    color: $primary-900;
  }
}

.base-font-size {
  font-size: rem($gutter-size);
}

// Card title styles, usually used with h4
.card-title {
  font-weight: 600;
  font-size: rem(14px);
  margin: 0;
}

// Page title
.page-title {
  font-size: rem(16px);
  font-weight: 600;
  margin-bottom: 0;
}

.pc-fix-badge-card {
  width: 100%;

  .ebs-badge__container {
    transform: none;
    margin-right: rem(5px);
  }
}

.pc-btn-icon {
  .ebs-button {
    @include flex-center;
  }
}

.pc-modal--extra-small .ebs-modal {
  max-width: rem(250px);
}

.ebs-select__wrapper {
  &.select-min-width {
    min-width: rem(150px);
  }

  &.select-max-width {
    max-width: 100%;
  }
}

.pc-select--hide-value {
  .ebs-select {
    display: none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}
.rc-table-summary {
  border: 0;
}
