.pc-act-modal {
  &__header {
    > .ebs-space__item:last-child {
      overflow: auto hidden;
      margin: rem(-$gutter-size);
      padding: rem($gutter-size);
      margin-left: 0;
      border-left: 1px solid $border-color;
    }

    .step-item__title {
      white-space: nowrap;
    }
  }

  &__row {
    margin: 0;
  }

  &__sidebar {
    background-color: $white;
    padding: rem($gutter-size);
    border-right: 1px solid $border-color;

    .ebs-alert {
      margin-top: rem($gutter-size);
      padding: rem($gutter-size / 2);
    }

    .ebs-avatar {
      display: none;
    }

    .ebs-avatar__inline-alt {
      margin-left: 0;
    }

    .ebs-avatar__inline {
      margin-top: rem($gutter-size / 2);
    }

    .ebs-badge {
      margin-top: rem($gutter-size);

      &__container--text {
        font-size: rem(12px);
        transform: translate3d($gutter-size, -50%, 0);
      }

      span {
        width: 100%;
        align-items: flex-start;
        text-align: left;
        justify-content: left;
      }
    }
  }
}
