.report-chart__card {
  height: 100%;

  .ebs-card__body {
    width: 100%;
    height: 100%;
    @include flex-center;
  }

  .recharts-responsive-container,
  .recharts-legend-wrapper,
  .recharts-wrapper,
  .recharts-wrapper > svg {
    max-width: 100%;
    max-height: 100%;
  }

  .recharts-responsive-container,
  .recharts-wrapper {
    height: 100% !important;
  }
}
