.pc-limits-description {
  .ebs-card__body {
    background: $white;
    padding: 0;

    .ebs-space {
      display: flex;
      height: 100%;
    }
  }
  &__row--border-bottom {
    border-bottom: rem(1px) solid $grey-300;
  }
}
