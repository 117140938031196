.pc-classifier {
  &-form__categories__table.ebs-table {
    td.rc-table-cell {
      padding-left: rem(11px);
      padding-right: rem(11px);
    }
    .rc-table-tbody tr.rc-table-row {
      &:last-child {
        td.rc-table-cell {
          border-bottom: none;
        }
      }
    }
  }

  &__formula {
    margin: rem(0 4px);
  }
}
