.pc-committees__table.ebs-table {
  .rc-table-tbody tr.rc-table-row {
    td.rc-table-cell {
      padding: 0 rem(8px);
      height: rem(40px);
      border-bottom: 1px solid $primary-color !important;
      border-right: 1px solid $grey-300;

      &.table-cell--border-right-none {
        border-right: none;
      }
      &.table-cell--border-bottom-default {
        border-bottom: 1px solid $grey-300 !important;
      }
    }

    &:nth-child(odd) td.rc-table-cell,
    &:hover:nth-child(even) td.rc-table-cell {
      background: $white;
    }
  }
}
