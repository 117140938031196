.company-form {
  .ebs-card {
    overflow: visible;
  }

  // Overrides from kit
  .ebs-form__field__label {
    text-align: right;
  }
  .ebs-form__group {
    .ebs-space__item {
      flex: 1;
    }
  }
}
.company-btn__arrow svg {
  vertical-align: middle;
  margin-top: rem(-2px);
}

.company-collapse__count {
  @include flex-center;
  width: rem(26px);
  height: rem(26px);
  border-radius: rem(13px);
  font-size: rem(14px);
  line-height: rem(26px);
  text-align: center;
  border: 1px solid $border-color;
}
.company-collapse__title {
  font-size: rem(15px);
  font-weight: 600;
  color: $text-color;
}

.form-divider {
  margin: rem(20px -30px);
  border-bottom: 1px solid $border-color;

  &.vertical {
    position: absolute;
    border-bottom: none;
    border-left: 1px solid $border-color;
    width: 0px;
    height: 100%;
    margin: 0;
    top: 0;
  }
}

.form-heading {
  font-size: rem(14px);
  color: $text-color;
  font-weight: 500;
  margin-bottom: rem(20px);
}

// Field label
.form-field__label {
  font-size: rem(14px);
  width: rem(320px);
  margin-left: auto;
  margin-right: 0;
  margin-bottom: rem(10px);
}

// Radio field
.ebs-radio__inline {
  display: flex;
  align-items: center;

  .ebs-radio__wrapper {
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: rem(20px);
    }
  }
}

@include mq($from: desktop) {
  .form-col--border-right {
    border-right: 1px solid #d5d5d5;
  }
}

.form-actions {
  padding: rem(15px 20px);
  border-top: 1px solid #d5d5d5;
  width: 100%;
}

.form-wizard__container {
  .ebs-collapse__group .ebs-collapse__header:hover {
    cursor: pointer;
    background-color: $grey-50;
  }
}
