.my-profile {
  &__side-menu {
    background: #f8f8f8;
    width: rem(256px);
    height: calc(100vh - #{rem(58px)});
    border-right: 1px solid $border-color;
    position: fixed;
  }

  &__side-menu__title {
    @include fontSemiBold;
    font-size: rem(16px);
    padding: rem(25px 0 25px 20px);
  }

  &__side-menu__list-item {
    height: rem(40px);
    @include fontMedium;
    font-size: rem(13px);
    padding: rem(10px 0 10px 50px);
    margin: rem(0 5px);
    color: $heading-color;
    position: relative;

    svg {
      color: $heading-color;
      width: rem(18px);
      height: rem(18px);
      position: absolute;
      left: rem(20px);
    }

    &:hover {
      color: $primary-color;
      svg {
        color: $primary-color;
      }
    }

    &.active {
      border-radius: $border-radius;
      background: $white;
      box-shadow: $box-shadow;
      position: relative;

      &::after {
        content: ' ';
        display: block;
        position: absolute;
        top: calc(50% - 5px);
        right: rem(15px);
        width: rem(9px);
        height: rem(9px);
        border-top: 1px solid $grey-400;
        border-right: 1px solid $grey-400;
        transform: rotate(45deg);
      }

      svg {
        color: $primary-color;
      }
    }
  }
}
