// Gutters
$gutter-size: 16px;

// Grid
$grid-xxl-size: 1640px;
$grid-xl-size: 1520px;
$grid-lg-size: 1312px;
$grid-md-size: 1088px;
$grid-sm-size: 896px;

// Components
$border-color: $grey-300;
$box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);

// Typography colors
$heading-color: #282828;
$text-color: $black;
$text-color-secondary: #333;
$text-color-light: #747474;

$font-family: 'Montserrat', sans-serif;
$font-size-base: 14.4222px;
$font-weight-base: 500;

// Buttons
$btn-font-size: 13px;
$btn-font-weight: 600;
$btn-font-weight-sm: $btn-font-weight;
$btn-font-weight-lg: $btn-font-weight;

$btn-type-ghost-border-color: $border-color;
$btn-type-ghost-background: $white;
$btn-type-ghost-color: $black;

// Details card
$details-card-padding: 20px;
$details-card-header-icon-size: 70px;

// Layout
$layout-top-bar-side-left-magin-left: 0;
$layout-top-bar-border-color: 1px solid $primary-900;
$layout-top-bar-background: $primary-color;

$layout-top-bar-toggler-color: $white;
$layout-top-bar-toggler-border-color: $primary-900;
$layout-top-bar-toggler-border-color: $text-color;

$layout-top-bar-avatar-background: $white;
$layout-top-bar-avatar-alt-color: $white;
$layout-top-bar-avatar-color: $text-color;
$layout-top-bar-avatar-icon-btn-color: $white;

// Label
$label-height: 18px;
$label-padding: 0 5px;

// Sidebar
$sidebar-background: $text-color;
$sidebar-bottom-background: $text-color;
$sidebar-item-text-color: $white;
$sidebar-item-hover-background: $white;
$sidebar-item-hover-color: $primary-color;

// Table
$table-border: 1px solid $border-color;
$table-body-cell-border: transparent;
$table-body-mobile-cell-border: $table-border;
$table-head-font-weight: bold;
$table-body-font-size: 12px;
$table-head-font-size: 11px;
$table-head-background: $white;
$table-head-cell-border: transparent;
$table-sub-cell-border: $table-border;

// Collapse
$collapse-group-space-size: $details-card-padding;
$collapse-header-title-font-weight: 600;
$collapse-header-border-bottom: 2px solid;

// Form
$field-label-font-weight: 500;

// Tabs
$tab-border-bottom: 1px solid $border-color;
$tab-item-border-bottom-active: none;
