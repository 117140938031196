// Main Colors
$primary-color: #00a1b1;
$success-color: #3dc343;
$info-color: #2d75ee;
$warning-color: #f69808;
$danger-color: #d11d34;
$normal-color: #d4d4d4;

$white: #fff;
$black: #1d1d1b;

$primary-100: #c8fbef;
$primary-200: #94f7e7;
$primary-300: #5ce7dd;
$primary-400: #34ced0;
$primary-500: $primary-color;
$primary-600: #007e98;
$primary-700: #005f7f;
$primary-800: #004466;
$primary-900: #003154;

$success-100: #e4fcd9;
$success-200: #c3f9b4;
$success-300: #98ec8b;
$success-400: #6fdb6a;
$success-500: #3dc343;
$success-600: #2ca73d;
$success-700: #1e8c37;
$success-800: #137130;
$success-900: #0b5d2c;

$info-100: #d4eafe;
$info-200: #abd3fd;
$info-300: #80b6f9;
$info-400: #5f9df4;
$info-500: #2d75ee;
$info-600: #205acc;
$info-700: #1642ab;
$info-800: #0e2e8a;
$info-900: #081f72;

$warning-100: #fef3cd;
$warning-200: #fee39b;
$warning-300: #fcce69;
$warning-400: #f9b944;
$warning-500: #f69808;
$warning-600: #d37a05;
$warning-700: #b15e04;
$warning-800: #8e4602;
$warning-900: #763501;

$danger-100: #fcdbd1;
$danger-200: #fab1a4;
$danger-300: #f17b74;
$danger-400: #e35055;
$danger-500: #d11d34;
$danger-600: #b31538;
$danger-700: #960e39;
$danger-800: #790936;
$danger-900: #640534;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #e5e5e5;
$grey-300: #d5d5d5;
$grey-400: #c5c5c5;
$grey-500: #a5a5a5;
$grey-600: #888888;
$grey-700: #666666;
$grey-800: #444444;
$grey-900: #222222;

:export {
  primary: {
    color: $primary-color;
    color-100: $primary-100;
    color-200: $primary-200;
    color-300: $primary-300;
    color-400: $primary-400;
    color-500: $primary-500;
    color-600: $primary-600;
    color-700: $primary-700;
    color-800: $primary-800;
    color-900: $primary-900;
  }
  success: {
    color: $success-color;
    color-100: $success-100;
    color-200: $success-200;
    color-300: $success-300;
    color-400: $success-400;
    color-500: $success-500;
    color-600: $success-600;
    color-700: $success-700;
    color-800: $success-800;
    color-900: $success-900;
  }
  info: {
    color: $info-color;
    color-100: $info-100;
    color-200: $info-200;
    color-300: $info-300;
    color-400: $info-400;
    color-500: $info-500;
    color-600: $info-600;
    color-700: $info-700;
    color-800: $info-800;
    color-900: $info-900;
  }
  warning: {
    color: $warning-color;
    color-100: $warning-100;
    color-200: $warning-200;
    color-300: $warning-300;
    color-400: $warning-400;
    color-500: $warning-500;
    color-600: $warning-600;
    color-700: $warning-700;
    color-800: $warning-800;
    color-900: $warning-900;
  }
  danger: {
    color: $danger-color;
    color-100: $danger-100;
    color-200: $danger-200;
    color-300: $danger-300;
    color-400: $danger-400;
    color-500: $danger-500;
    color-600: $danger-600;
    color-700: $danger-700;
    color-800: $danger-800;
    color-900: $danger-900;
  }
  grey: {
    color-50: $grey-50;
    color-100: $grey-100;
    color-200: $grey-200;
    color-300: $grey-300;
    color-400: $grey-400;
    color-500: $grey-500;
    color-600: $grey-600;
    color-700: $grey-700;
    color-800: $grey-800;
    color-900: $grey-900;
  }
}
