.ebs-button__wrapper {
  .ebs-label--danger.ebs-label--icon {
    border-color: $primary-color;
  }

  &.ebs-button--ghost:hover {
    .ebs-label--danger.ebs-label--icon {
      background-color: $primary-color;
    }

    .ebs-icon {
      color: $white;
    }
  }
}
