.connect-wrapper {
  @include elevation-1;
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 80%;
  max-width: 500px;
  z-index: 5;
  top: 0;
  background: $white;
  transition: $transition-base;
  transform: translateX(-100%);

  &.is-open {
    transform: translateX(0);
  }

  .ebs-avatar__inline-alt {
    flex-direction: column;
  }

  .ebs-sidebar {
    background-color: $white;
  }

  .conversation-header {
    .ebs-avatar__inline-description {
      color: $text-color;
    }
  }
}

.edit-conversation {
  .chat-navigation__list .ebs-list-group__item .ebs-button__wrapper {
    position: absolute;
  }
}
