.company-tab {
  .active {
    border-bottom: none;
    position: relative;
  }

  .active::after {
    content: ' ';
    background-color: $primary-color;
    display: block;
    height: rem(3px);
    width: 100%;
    position: absolute;
    bottom: rem(-1px);
  }

  a {
    color: #1e1e1e;
    font-size: rem(14px);
    @include fontSemiBold;

    &.active {
      color: #000;
      font-size: rem(14px);
    }
  }
}
