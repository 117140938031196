.pc-categories__table.ebs-table {
  .rc-table-tbody tr.rc-table-row {
    td.rc-table-cell {
      border-bottom: 1px solid $grey-300;
      &:not(:nth-last-child(-n + 2)) {
        border-right: 1px solid $grey-300;
      }
    }
    &:last-child td.rc-table-cell {
      border-bottom: none;
    }
  }

  th.rc-table-cell:not(:nth-last-child(-n + 2)) {
    border-right: 1px solid $grey-300;
  }
}
