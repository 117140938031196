.pc-notifications {
  &--type-1 .ebs-avatar {
    background-color: $success-color;
  }
  &--type-2 .ebs-avatar {
    background-color: $warning-color;
  }
  &--type-3 .ebs-avatar {
    background-color: $danger-300;
  }
  &--type-4 .ebs-avatar {
    background-color: $danger-color;
  }
  &--type-5 .ebs-avatar {
    background-color: $danger-700;
  }
  &--type-6 .ebs-avatar {
    background-color: $danger-900;
  }
  &--type-7 .ebs-avatar {
    background-color: $black;
  }
  &--type-8 .ebs-avatar {
    background-color: $black;
  }

  &:not(:first-child) {
    margin-top: rem($gutter-size);
  }
}
