.pc-contracts__table {
  &__button--link {
    .ebs-button {
      @include fontMedium;
      font-size: rem(12px);
      color: $black;
    }
  }
  .rc-table-tbody tr.rc-table-row td.rc-table-cell {
    padding: 0 rem(8px);
    height: rem(50px);
    border-bottom: 1px solid $grey-300;
  }
}

.actions-col {
  .upload__container {
    display: none;
  }
}

.template-list-item {
  .ebs-form__field__control {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;

    .upload__container {
      .upload__file {
        margin-right: 15px;
      }

      .upload__status {
        display: none;
      }

      .upload__file__remove {
        display: none;
      }
    }
  }
}
