// Remove table border
// FIXME: Fix the table border in kit
.table-no-border {
  .rc-table-header,
  .rc-table-body,
  .rc-table-content {
    border: 0 !important;
  }

  .rc-table-tbody tr.rc-table-row:last-child td {
    border-bottom: 0 !important;
  }
}

.collapse-no-border {
  border: 0 !important;
  box-shadow: none !important;
}

.disable--requested {
  tr.disabled td {
    background: rgba($danger-color, 0.1) !important;
  }
}

// Color helpers
.color-primary {
  color: $primary-color !important;
}

.color-white {
  color: $white !important;
}

.color-success {
  color: $success-color !important;
}

.color-danger {
  color: $danger-color !important;
}

.color-info {
  color: $info-color !important;
}

.color-warning {
  color: $warning-color !important;
}

// Bg helpers
.bg-white {
  background-color: $white;
}

.bg-grey {
  background-color: $grey-100;
}

// Text helpers
.text-nowrap {
  white-space: nowrap !important;
}

.text-wrap {
  white-space: initial !important;
  word-break: normal !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

// Borders
.no-border {
  border: 0 !important;
  border-radius: 0 !important;
}

// Overflow
.overflow-hidden {
  position: relative;
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

// Truncate
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Transition
.transition-width {
  transition: width $transition-time, max-width $transition-time;
}

.justify-space-between {
  justify-content: space-between;
}

.align-items-end {
  align-items: flex-end;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-center {
  @include flex-center;
}

.pc-fix-calendar {
  max-width: rem(238px);
}

.full-width {
  width: 100%;
}

.mx--20 {
  margin-left: rem(-20px) !important;
  margin-right: rem(-20px) !important;
}

// Font weight
.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700 !important;
}

.pc-searchbar {
  background-color: $background-content;
  margin: rem(-$gutter-size) rem(-$base-size) 0;
  padding: rem($gutter-size) rem($base-size);
  border-bottom: 1px solid;
  color: $border-color;
}

// Displays
.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.pc-fix-card-badge {
  .ebs-badge {
    display: block;

    &__container {
      transform: translate3d(0, rem(-$gutter-size / 2), 0);
    }
  }
}

.word-break {
  word-break: break-all;
}

.hide_upload {
  .upload__container {
    display: none;
  }
}

.select-min-height {
  .ebs-select__options-items {
    max-height: min-content !important;
  }
}

.select-max-height {
  .ebs-select__options-items {
    max-height: 340px !important;
  }
}
