.statistic-card {
  &__button {
    button {
      @include flex-center;

      svg {
        color: $primary-color;
        margin-left: rem(8px);
      }
    }
  }
  &__info {
    padding: rem(15px 20px);

    h3 {
      font-weight: 600;
      font-size: rem(24px);
    }

    h4 {
      font-weight: 600;
      font-size: rem(18px);
    }

    p {
      text-transform: uppercase;
    }
  }
  &__children {
    border-top: 1px solid $border-color;
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;

    &:empty {
      display: none;
    }
  }
  &__child {
    width: 50%;
    padding: rem(15px 20px);
    word-break: break-all;

    &:nth-child(n + 3) {
      padding-top: 0;
    }

    &--bordered:not(:first-child) {
      border-left: 1px solid $border-color;
    }
  }

  &--primary {
    header {
      background-color: $primary-color;
      color: $white;
      border-color: #1f1f1f;
    }
  }
}

.statistic-period__select {
  min-width: 150px;
}
