.pc-table__subrows {
  .rc-table-cell {
    background-color: #fff !important;

    &:not(:first-child) {
      border-left: 1px solid $border-color !important;
    }
  }

  &__total .rc-table-cell {
    background-color: #f8f8f8 !important;
    border-top: 1px solid $border-color !important;
    border-bottom: 1px solid $border-color !important;
    border-right: 1px solid $border-color;
    padding: rem(8px);

    font-size: rem(11px);
    font-weight: 600;
    text-transform: uppercase;
  }

  .rc-table-tbody {
    .pc-table__subrows__column {
      padding: 0;

      .pc-table__subrows__item {
        padding: rem(16px);

        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }

  &__cols {
    max-height: 11.75rem;
    overflow-y: scroll;
    display: flex;
  }

  &__turnovers {
    padding: 0 !important;
    border-right: 1px solid $border-color !important;
    border-bottom: none !important;
    height: 100%;

    display: flex;
    flex-direction: column;
    flex: 0 1 100%;
    white-space: nowrap !important;

    &:last-child {
      border-right: none !important;
    }
  }

  &__item {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }
  }
}
