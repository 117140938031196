.company-card__avatar {
  .ebs-avatar__inline-alt {
    color: $primary-color;
    text-transform: uppercase;

    .ebs-avatar__inline-description {
      color: $black;
      font-size: rem(16px);
      text-transform: none;
    }
  }

  .ebs-avatar {
    background-color: $grey-100;
  }

  svg {
    color: $black;
  }
}

.company-card__body {
  .rc-table-content {
    border: none;
  }
}

.request-comment__item:not(:last-child) {
  border-bottom: 1px solid $border-color;
}
