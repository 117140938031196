.authentication-modal {
  position: fixed;
  min-height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background-color: rgba($black, 0.7);

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  .authentication-modal-wrapper {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: rem(1280px);
    min-height: rem(500px);
    height: auto;
    border-radius: rem(12px);
    @include elevation-2;

    @include mq($until: tablet) {
      flex-direction: column;
    }

    .authentication-modal-preview {
      background-color: $primary-color;
      max-width: rem(480px);
      width: 100%;
      border-top-left-radius: rem(12px);
      border-bottom-left-radius: rem(12px);
      padding: rem(35px);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .authentication-modal-preview-logo,
      .authentication-modal-preview-footer {
        margin: 0 auto;
        width: 100%;
        max-width: rem(240px);
      }

      .authentication-modal-preview-logo {
        height: 100%;
        display: flex;
        align-items: center;
        margin-bottom: rem(20px);

        img {
          max-width: rem(220px);
        }
      }

      .authentication-modal-preview-button {
        background-color: $primary-600;
        border-color: $primary-600;
        color: $white;

        .ebs-button,
        .ebs-icon {
          color: $white;
        }
      }

      @include mq($until: tablet) {
        border-bottom-left-radius: 0;
        border-top-right-radius: rem(12px);
        max-width: 100%;
      }
    }

    .authentication-modal-form {
      position: relative;
      width: 100%;
      background-color: $white;
      border-top-right-radius: rem(12px);
      border-bottom-right-radius: rem(12px);

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .authentication-modal-form-header {
        width: 100%;
        height: auto;
        padding: rem(20px 160px);
        border-bottom: 1px solid $grey-200;

        @include mq($until: tablet) {
          padding: rem(20px 80px 20px 0);
        }

        @include mq($until: mobile) {
          padding: rem(20px 40px 20px 0);
        }

        .authentication-modal-form-title {
          @include fontSemiBold;
          font-size: rem(20px);

          &:not(:last-child) {
            margin-bottom: rem(10px);
          }
        }

        .authentication-modal-form-description {
          @include fontRegular;
          font-size: rem(14px);
          color: $heading-color;
        }
      }

      .authentication-modal-form-content {
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: rem(20px 160px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include mq($until: tablet) {
          padding: rem(20px 80px 20px 0);
        }

        @include mq($until: mobile) {
          padding: rem(20px 40px 20px 0);
        }

        .ebs-space__item {
          white-space: nowrap;
        }

        .authentication-modal-form-actions {
          display: flex;
          white-space: nowrap;

          .ebs-button {
            @include elevation-2;
            width: 100%;

            &:first-child:last-child {
              width: auto;
            }

            &:not(:last-child) {
              margin-right: rem(10px);
            }

            &:not(:first-child) {
              margin-left: rem(10px);
            }
          }
        }

        .authentication-modal-form-redirect {
          .ebs-icon {
            font-size: rem(18px);
            margin-right: rem(7.5px);
            vertical-align: rem(-3.5px);
          }
        }
      }

      .authentication-modal-form-footer {
        width: 100%;
        height: auto;

        border-top: 1px solid $grey-200;
        border-bottom-right-radius: rem(12px);
        padding: rem(40px 160px);
        background-color: $grey-100;
        color: $heading-color;
        text-align: center;

        @include mq($until: tablet) {
          border-bottom-left-radius: rem(12px);
        }
      }

      @include mq($until: tablet) {
        border-top-right-radius: 0;
        border-bottom-left-radius: rem(12px);
      }
    }
  }
}
