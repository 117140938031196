.steps {
  display: flex;
  margin-bottom: rem(15px);
}

.step-item {
  display: flex;
  align-items: center;
  padding: rem(7px);
  height: rem(36px);
  border: 1px solid $border-color;
  background-color: #fff;
  border-radius: rem(50px);

  &:not(:last-child) {
    margin-right: rem(10px);
  }

  &:hover {
    cursor: pointer;
  }

  // Active
  &--active {
    box-shadow: $box-shadow;

    .step-item__count {
      background-color: $primary-color;
      color: #fff;
    }
  }

  &--completed {
    background-color: #eaeaea;
    box-shadow: $box-shadow;

    .step-item__count,
    svg {
      font-size: 24px;
      color: $primary-color;
    }
  }

  &--disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: $grey-400;
  }

  &--disabled &__count {
    background-color: $grey-500;
    color: $grey-700;
  }

  &--disabled &__title {
    color: $grey-600;
  }

  &__count {
    border-radius: 50%;
    background-color: $grey-200;
    color: $text-color;
    min-width: rem(24px);
    height: rem(24px);
    line-height: rem(24px);
    text-align: center;
    font-size: rem(13px);
    font-weight: 500;
    margin-right: rem(7px);
  }

  &__title {
    font-size: rem(14px);
    font-weight: 600;
    color: $text-color;
    margin-right: rem(7px);
  }

  &__info {
    font-size: rem(16px);
    height: rem(16px);
    color: $text-color-light;
  }
  &__desc {
    font-size: rem(12px);
    max-width: rem(150px);
  }
}
