.ebs-label {
  font-size: rem(12px);
  @include fontMedium;

  // FIXME: Solve styles for inputs with label
  & + * {
    margin-top: rem(5px);
  }

  .ebs-icon--check {
    padding: rem(2px);
  }

  &--danger {
    color: $primary-color;
    border-color: $primary-color;
  }

  &--fill.ebs-label--danger:not(.ebs-label--icon):not(.disabled) {
    background-color: $primary-color;
    color: $white;
  }
}
