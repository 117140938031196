.ebs-tooltip {
  &__user-menu {
    padding: rem(10px 15px);
  }

  &__user-menu__title {
    position: relative;
    @include fontMedium;
    font-size: rem(13px);
  }

  &__user-menu__list {
    list-style: none;
    padding: 0;
    margin: rem(5px 0 0 0);
    font-size: rem(13px);
    @include fontSemiBold;

    li {
      width: 100%;
      height: rem(40px);
      line-height: rem(40px);
      position: relative;
      padding-left: rem(30px);
      cursor: pointer;

      &:hover,
      &:hover a,
      &:hover svg {
        color: $primary-color;
      }

      &.disabled {
        margin: 0 rem(-15px);
        padding-left: rem(45px);
        width: calc(100% + 30px);
        background: $border-color;
        color: $grey-600;
        cursor: not-allowed;

        svg {
          color: $grey-600;
          left: rem(20px);
        }
      }

      a {
        color: $black;
        line-height: rem(40px);
        height: rem(40px);
        display: block;
      }

      svg {
        color: $black;
        position: absolute;
        top: 50%;
        left: rem(5px);
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &__languages,
  &__companies {
    border: none;
    overflow: visible;

    .ebs-collapse__header {
      padding: unset;
      border-bottom: 0;
    }

    .ebs-collapse__body {
      padding: unset;
      margin: rem(0 -15px);
    }

    .ebs-loader__spinner {
      position: absolute;
      left: rem(4px);
    }
  }

  &__companies {
    .ebs-icon {
      color: $primary-color;
    }
  }
}

.ebs-avatar__img {
  overflow: hidden;
}
