.pc-rates__card {
  .ebs-card__header {
    .ebs-card__header__content > * {
      @include fontSemiBold;
    }
  }
  .ebs-card__body {
    background: $white;

    .col:first-of-type {
      border-right: rem(1px) solid $grey-300;
    }
  }
}

.pc-rates__table.ebs-table {
  .rc-table-content {
    border-radius: 0;
  }

  .rc-table-cell {
    height: rem(50px);

    &:not(.has-children) {
      width: 50%;
    }
  }
  .rc-table-tbody tr.rc-table-row td.rc-table-cell {
    text-align: center;
    border-left: rem(1px) solid $grey-300;
    border-bottom: rem(1px) solid $grey-300;

    &:first-of-type {
      border-left: none;
    }
  }
  .rc-table-thead tr th.rc-table-cell {
    text-align: center;
    border-left: rem(1px) solid $grey-300;
    border-bottom: rem(1px) solid $grey-300;

    &:first-of-type {
      border-left: none;
    }
  }
}

.pc-rates__table-standard.ebs-table {
  .rc-table-content {
    border-radius: 0;
  }

  .rc-table-tbody tr.rc-table-row td.rc-table-cell {
    text-align: center;
    border-left: rem(1px) solid $grey-300;
    border-bottom: rem(1px) solid $grey-300;

    padding: rem(8px);
    &:first-of-type {
      border-left: none;
    }
  }
  .rc-table-thead tr th.rc-table-cell {
    text-align: center;
    border-left: rem(1px) solid $grey-300;
    border-bottom: rem(1px) solid $grey-300;

    &:first-of-type {
      border-left: none;
    }
  }
}
