.pc-classifiers__table.ebs-table {
  .rc-table-tbody tr.rc-table-row td.rc-table-cell {
    white-space: normal;
    padding: 0 rem(8px);
    height: rem(50px);
    border-bottom: 1px solid $grey-300;

    &:nth-of-type(n + 5):not(:last-of-type) {
      border-left: 1px solid $grey-300;
    }
  }

  th {
    padding: rem(8px);
  }
}
