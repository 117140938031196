.ebs-sidebar {
  &__item .ebs-icon--user-profile {
    font-size: rem(32px);
    margin-left: rem(-8px);
  }

  &__top .ebs-tooltip__trigger {
    margin: unset;
    padding: unset;
  }
}
