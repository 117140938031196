.pc-radio {
  height: rem(14px);
  width: rem(14px);
  border-radius: 50%;
  outline: none;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  margin: 0;
  background-color: #fff;
  border: 1px solid $border-color;
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform 0.3s ease, opacity 0.2s;
    width: rem(12px);
    height: rem(12px);
    border-radius: 50%;
    background: #fff;
    transform: scale(0.7);
  }

  &:checked {
    background-color: $primary-color;
    border-color: $primary-color;
    transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2), opacity 0.3s;
    opacity: 1;
  }

  &:disabled {
    background-color: $grey-500;
    cursor: not-allowed;
    opacity: 0.9;

    &:checked {
      background-color: $grey-500;
      border-color: $grey-500;
    }
    & + label {
      cursor: not-allowed;
    }
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        border-color: $primary-color;
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px $primary-100;
  }
}
