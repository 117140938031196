.pc-notify {
  padding: 0;
  width: rem(320px);
  overflow: hidden;
  border-radius: $border-radius;
  box-shadow: $box-shadow;

  .ebs-loader > .ebs-loader__spinner {
    left: calc(50% - 20px);
  }

  .ebs-list-group__item {
    padding: 0;
    white-space: pre-wrap;
  }

  .ebs-chips {
    padding: rem($gutter-size);
    margin: 0;
    border: none;
    border-radius: 0;

    &--checked:hover {
      .ebs-label,
      .ebs-icon {
        color: $white;
        border-color: $white;
      }
    }
  }

  &__container {
    overflow: auto;
    border: 0;
    max-height: 50vh;
  }

  &__description {
    word-break: break-word;
  }

  &__pagination {
    border-top: 1px solid $border-color;
  }
}
