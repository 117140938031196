.pc-company-rates__table {
  .rc-table-cell {
    width: 45%;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
