.pc-invite-user {
  h3 {
    text-align: center;
    font-weight: 600;
  }

  .ebs-form__field__wrapper {
    @include flex-center;
  }
}
