.my-profile {
  .ebs-layout__content {
    padding: 0;
  }

  &__content-wrapper {
    margin-left: rem(256px);
    padding: rem(15px 36px);
  }

  &__user-avatar {
    width: rem(70px);
    height: rem(70px);
    border-radius: rem(50px);
    background-color: #f6f6f6;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #f5f5f5;
  }

  .form-group {
    border-bottom: none;
    padding: rem(25px 30px);
  }

  .form-divider {
    width: 100%;
    position: absolute;
    left: 0;
    margin: 0;
  }

  .ebs-card__body {
    position: relative;
  }

  .ebs-tabs + .ebs-tabs__content {
    display: none;
  }

  .ebs-layout__footer {
    @media (max-width: 852px) {
      width: rem(calc(100% - 256px));
    }

    @media (min-width: 852px) {
      width: 100%;
      margin-left: rem(256px);
    }
  }

  .rates-select,
  .rates-date-picker {
    min-width: rem(250px);
  }
}
