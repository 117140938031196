.multiple-check-box-select {
  @include fontMedium;
  position: relative;

  &__select {
    border-radius: rem(4px);
    border: 1px solid $border-color;
    line-height: $actions-height - 2;
    height: $actions-height;
    display: flex;
    padding: rem(0 10px);
    cursor: pointer;
    white-space: nowrap;

    svg {
      margin-left: rem(22px);
      font-size: rem(11px);
      height: $actions-height - 2;
    }
  }

  &__select-options {
    z-index: 9;
    display: none;
    background-color: $white;
    border-radius: rem(4px);
    border: 1px solid $grey-300;
    position: absolute;
    right: 0;
    width: rem(360px);
    margin-top: rem(5px);

    &__header {
      width: 100%;
      color: $black;
      border-bottom: 1px solid $grey-400;
      padding: rem(8px 15px);
    }

    &__body {
      padding: rem(15px);
      display: block;
      overflow: hidden;
    }

    &__label {
      width: rem(160px);
      float: left;
      margin: rem(3px 0);
      cursor: pointer;
    }
  }

  &.active &__select-options {
    display: block;
  }

  &.active &__select,
  &__select:hover {
    border-color: $primary-color;
    background-color: $primary-color;
    color: $white;

    svg {
      color: $white;
    }
  }
}
