.description-item {
  &__label {
    @include fontSemiBold;
    font-size: rem(12px);
    text-transform: uppercase;
    line-height: 1.25;
    max-width: rem(160px);
  }

  &__value {
    font-size: rem(16px);
    font-weight: 600;
    .sup {
      position: relative;
      bottom: 0.9ex;
      font-size: 60%;
    }
  }
}
