.notification-card {
  background-color: #fff;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 15px 20px;
}

.pc-avatar__invoice.pc-avatar--small {
  background-color: $grey-200;

  .ebs-icon {
    color: $text-color;
  }
}
