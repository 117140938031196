.invoice-details {
  padding: 10px 0;

  &__label {
    font-size: rem(12px);
    font-weight: 600;
    color: $text-color;
    margin-bottom: 8px;
  }
  &__value {
    font-size: rem(16px);
    font-weight: 500;
    color: $text-color;
  }
}
