.pc-kyc {
  margin-top: rem($gutter-size / 2);

  > .ebs-space__item:first-child {
    width: 100%;
  }

  &__info {
    width: rem(100px);
    text-align: right;
  }

  &__report {
    border-bottom: 1px solid $border-color;
  }

  .ebs-textarea {
    min-width: rem(300px);
    max-width: 100%;
  }
}
