.pc-user-search {
  .ebs-select-dropdown__container {
    display: none;
  }

  .ebs-avatar__container {
    display: flex;
    align-items: center;

    > .ebs-avatar__inline {
      flex: 1;
    }

    .spinner-container {
      margin-right: 2px;

      .ebs-loader__spinner--regular {
        height: 25px;
        width: 25px;
        border-width: 4px;
      }
    }
  }
}
