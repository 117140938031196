.pc-big-avatar {
  &__icon {
    @include flex-center;
    float: left;
    margin: 0;
    width: rem($details-card-header-icon-size);
    height: rem($details-card-header-icon-size);
    margin-right: rem($details-card-header-icon-margin-right);
    border-radius: 100%;
    background-color: $background-content;

    .ebs-icon {
      font-size: rem(24px);
    }
  }

  &__subtitle {
    display: block;
    margin-top: rem($details-card-padding-size / 2);
  }
}
.pc-avatar--small {
  $size: 30px;
  width: $size;
  height: $size;
  border-radius: $size / 2;
  box-shadow: $box-shadow;
  background-color: $white;
  @include flex-center;
}
