.company-details {
  &__main {
    border: 1px solid $border-color;
    border-radius: $border-radius;
    margin-bottom: rem(25px);

    .ebs-card__header {
      padding: rem(20px);
      border-bottom: 1px solid $border-color;
    }
  }

  // Description
  &__description {
    padding-top: rem(20px);

    &__label {
      font-size: rem(12px);
      font-weight: 600;
      color: $text-color;
      margin-bottom: rem(8px);
    }

    &__value {
      font-size: rem(14px);
      font-weight: 500;
      color: $text-color;
    }

    &--heading {
      @include flex-column;
      align-items: flex-start;
      justify-content: center;
      padding-top: rem(20px);
      padding-bottom: rem(20px);
      padding-right: rem(20px);
      height: 100%;
      border-right: 1px solid $border-color;

      .company-details__description__label {
        font-size: rem(11px);
        font-weight: 700;
        text-transform: uppercase;
      }

      .company-details__description__value {
        font-size: rem(16px);
      }
    }
  }

  &__description__row {
    margin: 0;
    padding: 0 rem($gutter-size);

    .col:last-child .company-details__description--heading {
      @media (min-width: 1137px) {
        border-right: 0;
      }
    }
  }

  &__description__row:first-child {
    border-bottom: 1px solid $border-color;
  }

  &__description__row:last-child .company-details__description {
    padding-bottom: rem(20px);
  }

  &__description__row .flex-grow {
    @media (min-width: 1137px) {
      flex-grow: 1;
    }
  }

  &__icon {
    float: left;
    margin: rem(0 20px 0 0);
    width: rem(70px);
    height: rem(70px);
    border-radius: rem(50px);
    background-color: #f6f6f6;

    svg {
      width: rem(24px);
      height: rem(24px);
      margin: rem(22px) auto;
      display: block;
    }
  }

  &__title-box {
    float: left;
  }

  &__title {
    display: block;
    @include fontMedium;
    font-size: rem(18px);
    margin-bottom: rem(10px);
  }

  &__subtitle {
    @include fontSemiBold;
    font-size: rem(11px);
    line-height: rem(11px);
    text-transform: capitalize;
    padding: rem(0 6px);
  }

  &__collapse {
    margin-bottom: rem(25px);

    .ebs-collapse__body {
      padding: 0;
      overflow: hidden;
    }

    .ebs-collapse__title {
      @include fontSemiBold;
      font-size: rem(14px);
    }

    .ebs-collapse__header-action {
      color: $grey-700;
    }

    .ebs-collapse__content {
      display: block;
      overflow: hidden;
    }

    &.background .ebs-collapse__content {
      background-color: #f6f6f6;
    }

    &__conclusions {
      .ebs-list-group {
        background: unset;
        border: unset;
      }
    }
  }

  &__contact-cards {
    display: flex;
    padding: rem(20px);
    overflow: auto hidden;
    width: calc(100% - #{rem(20px)});
  }

  &__contact-card {
    border: 1px solid $border-color;
    border-radius: 0;
    background-color: #fff;
    margin-right: rem(20px);
    max-width: rem(360px);
    min-width: rem(250px);
    width: 100%;
    padding: rem(16px 20px);
    position: relative;

    &.has-passport {
      padding-bottom: rem(32px);
    }
  }

  &__contact-passport {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    svg {
      margin-right: rem(5px);
      width: rem(16px);
      position: relative;
      top: rem(2px);
    }
  }
  &__buttons {
    border-radius: 0;
    border-bottom: none;
    border-left: none;
    height: rem(34px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__contact-passports {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: rem(5px);
      width: rem(16px);
      position: relative;
    }
  }

  &__contact-avatar {
    margin-bottom: rem(25px);

    .ebs-avatar__inline-alt {
      @include fontSemiBold;
      font-size: rem(14px);
      width: calc(100% - #{rem(30px)} - #{rem($gutter-size)});
    }

    .ebs-avatar__inline-description {
      color: $text-color;
      font-size: rem(11px);
    }
  }

  &__contact-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: rem(10px);
    }

    svg {
      position: relative;
      top: rem(3px);
      margin-right: rem(8px);
    }
  }

  &__bank {
    @include flex;
  }

  &__bank__cell {
    width: 100%;
    padding: rem(25px 18px);
    float: left;

    &:not(:last-child) {
      border-right: 1px solid $border-color;
    }
  }

  &__bank__label {
    display: block;
    @include fontSemiBold;
    font-size: rem(12px);
    margin-bottom: rem(5px);
    text-transform: uppercase;
  }

  &__bank__value {
    display: block;
    @include fontMedium;
    font-size: rem(16px);
  }
}

@media (max-width: 70.99165em) {
  .company-details__description__row .col:nth-child(even) .company-details__description--heading {
    border-right: 0;
  }
}

@media (max-width: 1137px) {
  .responsive-col {
    width: 100%;

    .rc-table-content tr td.rc-table-cell:first-child {
      width: 100%;
    }

    &__space .ebs-space__item {
      width: 100%;
    }

    &__button {
      float: right;
    }
  }
}
