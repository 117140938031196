.pc-penalties {
  &__col {
    min-width: rem(90px);

    &--date {
      min-width: rem(120px);
    }
  }

  &__cols {
    max-height: rem(104px);
    min-height: rem(50px);
    height: 100%;
    overflow: hidden auto;
    scroll-behavior: smooth;
    display: inline-flex;
    width: 100%;

    td.pc-penalties__turnovers {
      display: flex;
      flex-direction: column;
      flex: 0 1 100%;
      white-space: nowrap;
      border: none;

      &:not(:last-child) {
        max-width: rem(90px);
      }

      &:first-child {
        text-align: center;
        min-width: rem(120px);
      }

      &:last-child {
        .turnover__item {
          border-right: none;
        }
      }

      .turnover__item {
        @include flex-center-vert;
        height: 100%;
        border-right: 1px solid $border-color;
        border-collapse: collapse;
      }
    }
  }

  &__table {
    .rc-table-cell {
      background-color: #fff !important;

      &:not(:first-child) {
        border-left: 1px solid $border-color !important;
      }
    }

    .rc-table-thead .rc-table-cell.has-children {
      padding: 0;
      border: 0;
      visibility: hidden;
    }
    &__row-total .rc-table-cell {
      background-color: #f8f8f8 !important;
      border-top: 1px solid $border-color !important;
      border-bottom: 1px solid $border-color !important;
      border-right: 1px solid $border-color;
      padding: rem(8px);
      min-width: rem(90px);

      font-size: rem(11px);
      font-weight: 600;
      text-transform: uppercase;
    }

    .rc-table-tbody {
      .pc-penalties__turnovers {
        padding: 0;
        min-width: rem(90px);

        .turnover__item {
          padding: rem(8px);

          &:not(:last-child) {
            border-bottom: 1px solid $border-color;
          }
        }
      }
    }
  }

  // Penalties column
  &__penalty {
    .ebs-radio__input {
      margin: 0;
    }
    .ebs-radio__group .ebs-radio__dot {
      width: rem(13px);
      height: rem(13px);
    }
    .ebs-radio__group .ebs-radio__text {
      font-size: rem(12px);
      font-weight: 500;
      width: auto;
    }
    .ebs-radio__group .ebs-radio {
      top: 0 !important;
      width: rem(16px);
      height: rem(16px);
    }
    .ebs-radio__group .ebs-radio__wrapper:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &__filters {
    background-color: $background-content;
    margin: rem(-15px #{-$base-size} 15px);
    padding: rem($gutter-size) rem($base-size);
    border-bottom: 1px solid $border-color;
    color: $border-color;
  }

  &__turnovers__table {
    &.ebs-table .rc-table-content {
      overflow: visible;
    }
  }

  &__settings {
    .ebs-space:not(:first-of-type) {
      margin-top: rem(15px);
    }

    .ebs-input {
      max-width: rem(60px);
    }

    .pc-penalties__input-label,
    &__label {
      font-size: rem(12px);
      font-weight: 500;
      margin-bottom: rem(5px);
    }
  }
}
