.dashboard-card {
  position: relative;
  min-height: rem(250px);
  border-radius: $border-radius * 2;

  &--disabled {
    background: $black;
  }

  &--disabled &__cover {
    opacity: 0.5;
  }

  &__cover {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: rem(250px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &-container {
      background: url('../../../resources/icons/overlay.svg');
      background-size: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      padding: rem(0 50px);
      text-align: center;
      transition: 300ms background;

      &:hover {
        cursor: pointer;
        background-color: rgba($primary-color, 0.3);
      }

      @media (max-width: 768px) {
        padding: rem(0 16px);
      }
    }
  }

  &__icon,
  &__title {
    color: $white;
    z-index: 2;
  }

  &__icon {
    width: rem(62px);
    min-height: rem(62px);
    background: $primary-color;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(38px);

    .ebs-icon {
      font-size: rem(32px);
      color: $white;
    }
  }

  &__title {
    max-width: rem(300px);
    font-size: rem(17px);
    line-height: rem(24px);
    padding: rem(10px 0);
    margin-bottom: rem(32px);
    text-align: center;
  }
}
